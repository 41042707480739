import React, { useState, useEffect, useContext, useRef } from "react"
import { MiCardRadioButtons } from "components/Common/mi/MiCardRadioButtons"
import { MiModal } from "components/Common/mi/MiModal"
import {
  Col,
  Button,
  Row,
  CardBody,
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  Alert,
  CardText,
  Input,
  Label,
  FormGroup,
  Form,
  Carousel,
  CarouselItem,
} from "reactstrap"
import { MiDropodown } from "components/Common/mi/MiDropodown"
import { ConfiguratorContext } from "context/configuratorContext"

function ConfiguratorStep4({ step, vmc }) {

  let inputValidation = useRef(null)

  // const { calculateTotalAirflow,  calculateRoomsVolume} = vmc
  const { configuratorData, setConfiguratorData, validations, setValidations } = useContext(ConfiguratorContext)

  const defaultData = configuratorData && configuratorData["stepData" + step] ? configuratorData["stepData" + step] : {}

  const [rooms, setRooms] = useState(defaultData?.rooms || [])
  const [showModalRoom, setShowModalRoom] = useState(false)
  const [activeRoom, setActiveRoom] = useState(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [carouselStep, setCarouselStep] = useState([0])

  const [selectedRoomName, setSelectedRoomName] = useState("")
  const [extraction, setExtraction] = useState({title: "no",value: 0})
  const [immission, setImmission] = useState({title: "no",value: 0})
  const [extractionTubeLength, setExtractionTubeLength] = useState(null)
  const [extractionNumTubes, setExtractionNumTubes] = useState(null)
  const [immissionTubeLength, setImmissionTubeLength] = useState(null)
  const [immissionNumTubes, setImmissionNumTubes] = useState(null)
  const [roomSurface, setRoomSurface] = useState(null)
  const [roomHeight, setRoomHeight] = useState(null)
  const [validationRoom, setValidationRoom] = useState([])
  const [presetRoomValues, setPresetRoomValues] = useState(null)

  const checkValidation = async () => {
    inputValidation = [...document.querySelectorAll("input[type='number']:invalid")]
    if(validations && validations['stepData' + step]) {
      let hasExtraction = false
      let hasImmission = false
      const validatedRooms = rooms.map(room => {
        if (room.extraction?.value === 1) hasExtraction = true
        if (room.immission?.value === 1) hasImmission = true
        return room?.validation?.isValid
      })

      // // console.log('calculateTotalAirflow', await calculateTotalAirflow())

      validations['stepData' + step].isValid  = validatedRooms.filter(isValid => !isValid).length === 0 && hasExtraction && hasImmission
      validations['stepData' + step].hasExtraction  = hasExtraction
      validations['stepData' + step].hasImmission  = hasImmission

      setValidations({...validations})
    } else {
      setValidations({...validations, ['stepData' + step]: { isValid: rooms.length > 0 && inputValidation.length === 0 } })
    } 
  }

  const checkRoomValidation = () => {
    // console.log('checkRoomValidation', selectedRoomName, roomSurface, roomHeight, extraction, immission, extractionTubeLength, extractionNumTubes, immissionTubeLength, immissionNumTubes)
    const infoValidation = selectedRoomName?.length > 0 && Number(roomSurface) > 0 && Number(roomHeight) > 0
    const immissionValidation = immission?.value === 1 ? (Number(immissionTubeLength) > 0 && Number(immissionNumTubes) >= 0) : true
    const extractionValidation = extraction?.value === 1 ? (Number(extractionTubeLength) > 0 && Number(extractionNumTubes) >= 0) : true
    const validation = infoValidation && immissionValidation && extractionValidation
    const validationObj ={
      isValid: validation,
      infoValidation,
      immissionValidation,
      extractionValidation
    }
    setValidationRoom(validationObj)
    return validationObj
  }
  const checkRoomValidationDefault = (room) => {
    const {roomName, roomSurface, roomHeight, extraction, immission, extractionTubeLength, extractionNumTubes, immissionTubeLength, immissionNumTubes} = room
    // console.log('checkRoomValidation', selectedRoomName, roomSurface, roomHeight, extraction, immission, extractionTubeLength, extractionNumTubes, immissionTubeLength, immissionNumTubes)
    const infoValidation = roomName?.length > 0 && Number(roomSurface) > 0 && Number(roomHeight) > 0
    const immissionValidation = immission?.value === 1 ? (Number(immissionTubeLength) > 0 && Number(immissionNumTubes) >= 0) : true
    const extractionValidation = extraction?.value === 1 ? (Number(extractionTubeLength) > 0 && Number(extractionNumTubes) >= 0) : true
    const validation = infoValidation && immissionValidation && extractionValidation
    const validationObj ={
      isValid: validation,
      infoValidation,
      immissionValidation,
      extractionValidation
    }
    return validationObj
  }

  const resetRoom = () => {
    setSelectedRoomName("")
    setExtraction(null)
    setImmission(null)
    setExtractionTubeLength(null)
    setExtractionNumTubes(null)
    setImmissionTubeLength(null)
    setImmissionNumTubes(null)
    setRoomSurface(null)
    setRoomHeight(null)
    setPresetRoomValues(null)
    setCurrentAction(null)
    setValidationRoom(null)
  }
  const presetRoom = presetRoom => {
    setSelectedRoomName(presetRoom.roomName || "")
    setExtraction(presetRoom.extraction || null)
    setImmission(presetRoom.immission || null)
    setExtractionTubeLength(presetRoom.extractionTubeLength || null)
    setExtractionNumTubes(presetRoom.extractionNumTubes || null)
    setImmissionTubeLength(presetRoom.immissionTubeLength || null)
    setImmissionNumTubes(presetRoom.immissionNumTubes || null)
    setRoomSurface(presetRoom.roomSurface || null)
    setRoomHeight(presetRoom.roomHeight || null)
    setValidationRoom(presetRoom.validation || null)
  }

  // Aggiungi uno stato per l'azione corrente
  const [currentAction, setCurrentAction] = useState(null)

  // Funzione per aprire il popup e impostare l'azione corrente
  const openRoomPopup = (action, index) => {
    setCurrentAction(action)
    setShowModalRoom(true)
    setPresetRoomValues({ indexRoom: index, values: rooms[index] })
  }

  const checkCarouselStep = () => {
    const newCarouselStep = [0]
    if (immission?.value === 1) newCarouselStep.push(1)
    if (extraction?.value === 1) newCarouselStep.push(2)
    setCarouselStep(newCarouselStep)
  }

  useEffect(() => {
    if (presetRoomValues) {
      // console.log("presetRoomValues changed", presetRoomValues)
      checkCarouselStep()
      presetRoom(presetRoomValues.values)

    }
  }, [presetRoomValues])

  const toggle = () => {
    setShowModalRoom(!showModalRoom)
    setActiveIndex(0)
    resetRoom()
  }

  const createRoom = indexRoom => {
    const room = {
      roomName: selectedRoomName,
      roomSurface: roomSurface,
      roomHeight: roomHeight,
      extraction: extraction || null,
      immission: immission || null,
      extractionTubeLength: extractionTubeLength,
      extractionNumTubes: extractionNumTubes,
      immissionTubeLength: immissionTubeLength,
      immissionNumTubes: immissionNumTubes,
      validation: validationRoom
    }

    if (!currentAction || currentAction === "add") {
      setRooms([...rooms, room]) // Aggiungi una nuova stanza
    } else if (currentAction === "edit" && indexRoom !== null) {
      // Modifica la stanza esistente
      const updatedRooms = [...rooms]
      updatedRooms[indexRoom] = room
      setRooms(updatedRooms)
    } else if (currentAction === "copy" && indexRoom !== null) {
      // Duplica la stanza esistente
      // const roomToCopy = rooms[indexRoom]
      const allRooms = [...rooms]
      setRooms([...allRooms, room])
    } else if (currentAction === "delete" && indexRoom !== null) {
      // Elimina la stanza esistente
      const updatedRooms = rooms.filter((_, index) => index !== indexRoom)
      setRooms(updatedRooms)
    }

    toggle()
  }

  const roomAction = (action, index) => {
    switch (action) {
      case "edit":
        openRoomPopup("edit", index)
        // createRoom(null, index, "edit");
        break
      case "copy":
        openRoomPopup("copy", index)
        // createRoom(null, index, "copy");
        break
      case "delete":
        openRoomPopup("delete", index)
        // createRoom(null, index, "delete");
        break
      default:
        break
    }
  }
  const actionLabel = (action) => {
    switch (action) {
      case "edit":
        return "Modifica"
      case "copy":
        return "Copia"
      case "delete":
        return "Elimina"
      default:
        return "crea"
        break
    }
  }

  const roomNameOptions = [
    {
      label: "Cucina",
      value: "kitchen",
    },
    {
      label: "Sala",
      value: "room",
    },
    {
      label: "Salotto",
      value: "livingroom",
    },
    {
      label: "Camera",
      value: "bedroom",
    },
    {
      label: "Corridoio",
      value: "aisle",
    },
    {
      label: "Ripostiglio",
      value: "closet",
    },
  ]

  useEffect(() => {
    // // console.log('rooms',rooms)
    if (configuratorData && configuratorData["stepData" + step]) {
      configuratorData["stepData" + step].rooms = rooms
      setConfiguratorData({ ...configuratorData })
    }
    checkValidation()
  }, [rooms])

  useEffect(() => {
    checkCarouselStep()
   }, [extraction, immission])

  useEffect(() => {
    checkRoomValidation()
  }, [selectedRoomName, roomSurface, roomHeight, extraction, immission, extractionTubeLength, extractionNumTubes, immissionTubeLength, immissionNumTubes])

  useEffect(() => {
    if (configuratorData["stepData" + step]?.hasOwnProperty("rooms"))
    {
      const { rooms } = configuratorData["stepData" + step]
      if (rooms) {
        rooms.map((room, indexRoom) => {
          const validationObj = checkRoomValidationDefault(room)
          const updatedRooms = [...rooms]
          room['validation'] = validationObj
          updatedRooms[indexRoom] = room
          setRooms(JSON.parse(JSON.stringify(updatedRooms)))
        })
      }}
  }, [])

  return (
    <Row>
      <Col lg={6}>
      <Card className="no-shadow">
          <CardHeader className="p-3 rounded-xl">
            <CardImg
              alt="Sample"
              className="no-rounded"
              src="/imgs/Step-7.jpg"
            />
          </CardHeader>
          <CardBody>
            <CardTitle tag="h5">STEP 7</CardTitle>
            <CardText>
              In questa schermata vengono definiti i locali interni dell’edificio e i loro volumi. <br />
              Si raccomanda di inserire <b>tutti i locali</b> che costituiscono l’edificio, anche quelli in cui non è prevista né l’immissione né l’estrazione di aria (locali in cui non saranno presenti bocchette/griglie).
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Row>
        <Col>
            <h4 className="ms-2 mb-4">INSERISCI LE CARATTERISTICHE DEI LOCALI</h4>
        
            {
              !validations['stepData' + step]?.hasExtraction && 
              <Alert className="ms-2" color="danger">
                Attenzione: è necessario almeno un locale per l'estrazione di aria.
              </Alert>
            }
            {
              !validations['stepData' + step]?.hasImmission && 
              <Alert className="ms-2" color="danger">
                Attenzione: è necessario almeno un locale per l'immissione di aria.
              </Alert>
            }
            {rooms.length > 0 ? (
              rooms.map((room, index) => {
                return (
                  <Card
                    onMouseEnter={()=> setActiveRoom(index)}
                    className={"m-2 no-shadow rounded room-card " + (activeRoom === index ? "active" : "") + ' ' + (room.hasOwnProperty('validation') && !room.validation.isValid ? 'step-invalid' : '')}
                    key={"room_" + index}
                  >
                    <CardBody>
                      <CardTitle tag="h5">{room.roomName}</CardTitle>
                      <CardText className="text-truncate">
                        S: {room.roomSurface} m<sup>2</sup> | H:{" "}
                        {room.roomHeight}m | Imm:
                        <FormGroup
                          check
                          inline
                          style={{
                            transform: "translate(10px, 3px)",
                          }}
                        >
                          <Input type="checkbox"  checked={room.immission?.value === 1}/>
                        </FormGroup>
                        | Ext:
                        <FormGroup
                          check
                          inline
                          style={{
                            transform: "translate(10px, 3px)",
                          }}
                        >
                          <Input type="checkbox"  checked={room.extraction?.value === 1}/>
                        </FormGroup>
                      </CardText>
                    </CardBody>
                    <div className="wrapper-menu">
                      <MiDropodown
                        label=""
                        transparent={true}
                        items={[
                          {
                            label: "Modifica",
                            id: "edit",
                          },
                          {
                            label: "Duplica",
                            id: "copy",
                          },
                          {
                            label: "Elimina",
                            id: "delete",
                          },
                        ]}
                        onChange={e => {
                          // console.log(e.target.dataset.item)
                          roomAction(e.target.dataset.item, index)
                        }}
                      />
                    </div>
                  </Card>
                )
              })
            ) : (
              <>
                <h3 className="ms-2">Nessun locale disponibile</h3>
                <p className="ms-2">
                  Aggiungi un nuovo locale al tuo progetto.
                </p>
              </>
            )}
            <Button className="ms-2 mt-3" color="primary" onClick={toggle}>
              <i
                className="align-middle me-2 d-inline-block ion ion-ios-add-circle-outline"
                style={{
                  fontSize: 30,
                }}
              ></i>{" "}
              Aggiungi un locale
            </Button>

            <MiModal
            size={currentAction === 'delete' ? "lg" :"xl"}
              toggle={toggle}
              title={String(actionLabel(currentAction)).toUpperCase()+" LOCALE " + selectedRoomName}
              show={showModalRoom}
              handleBack={() => {
                if (carouselStep.length > 1) {
                  if (activeIndex > 0) {
                    const newindex = carouselStep.findIndex(stepIndex => stepIndex === activeIndex)
                    setActiveIndex(newindex - 1)
                  } else {
                    toggle()
                  }
                } else {
                  toggle()
                }
              }}
              handleSave={() => {
                if (carouselStep.length > 1) {
                  if (activeIndex < carouselStep.length - 1) {
                    const newindex = carouselStep.findIndex(stepIndex => stepIndex === activeIndex)
                    setActiveIndex(newindex + 1)
                  } else {
                    createRoom(presetRoomValues?.indexRoom)
                  }
                } else {
                  createRoom(presetRoomValues?.indexRoom)
                }
              }}
              labelBtnSave={activeIndex === carouselStep.length - 1 ? "Salva e procedi" : "Avanti"}
            >
              {!currentAction || currentAction !== "delete" ? (
                <Carousel
                  activeIndex={activeIndex}
                  next={() => {}  }
                  previous={() => {}  }
                >
                  <CarouselItem
                    key={0}
                  >
                    <Row>
                      <Col lg={6}>
                        <Card
                          style={
                            {
                              // width: '18rem'
                            }
                          }
                          className="no-shadow rounded"
                        >
                          <img
                            alt="Sample"
                            className="rounded mx-3"
                            src="/imgs/Step7-b.png"
                          />
                          <CardBody>
                            <CardTitle tag="h5">SCHEDA DEFINIZIONE LOCALE</CardTitle>
                            <CardText>
                              Compila i dati del locale con tutte le informazioni richieste. <br />
                              Successivamente sarà comunque possibile modifica/aggiornare i dati inseriti.

                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6}>
                        <Form>
                          <FormGroup className="ms-2">
                            <Label className="mb-1" for="roomname">Nome locale</Label>
                            <Input
                              className="mb-3"
                              type="text"
                              name="roomname"
                              value={selectedRoomName || ""}
                              placeholder="i.e. Cucina, Sala, etc..."
                              onChange={value => {
                                setSelectedRoomName(value.target.value)
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="ms-2">
                            <Label for="example2" className="mb-1">
                              Superficie (m<sup>2</sup>)
                            </Label>
                            <Input
                              className="mb-3"
                              id="superficie"
                              name="superficie"
                              type="number"
                              onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                              value={roomSurface || ""}
                              onChange={value =>
                                setRoomSurface(value.target.value)
                              }
                              step={0.01}
                              min={1.0}
                              max={200.0}
                            />
                            { roomSurface && (roomSurface < 1.0 || roomSurface > 200.0) &&
                              <Alert 
                              color="warning" 
                              className='mt-3'
                              >
                                Inserisci un valore compreso tra 1.0 e 200.0
                              </Alert>
                            }
                          </FormGroup>
                          <FormGroup className="ms-2">
                            <Label for="example1"  className="mb-1">Altezza (m)</Label>
                            <Input
                              className="mb-3"
                              id="altezza"
                              name="altezza"
                              type="number"
                              onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                              value={roomHeight || ""}
                              step={0.01}
                              onChange={value =>
                                setRoomHeight(value.target.value)
                              }
                              min={1.0}
                              max={10.0}
                            />
                            { roomHeight && (roomHeight < 1.0 || roomHeight > 10.0) &&
                              <Alert 
                              color="warning" 
                              className='mt-3'
                              >
                                Inserisci un valore compreso tra 1.0 e 10.0
                              </Alert>
                            }
                          </FormGroup>
                        </Form>
                        <Row className="my-2">
                          <Col lg={6}>
                            <MiCardRadioButtons
                              defaultValue={immission || {
                                title: "no",
                                value: 0,
                              }}
                              onChange={value => {
                                // // console.log('immission',value)
                                setImmission(value)
                              }}
                              data={{
                                title: "Immissione",
                                items: [
                                  {
                                    title: "si",
                                    value: 1,
                                  },
                                  {
                                    title: "no",
                                    value: 0,
                                  },
                                ],
                              }}
                            ></MiCardRadioButtons>
                          </Col>
                          <Col xs={6} className="mb-2">
                            <MiCardRadioButtons
                              alternativeSelectedStyle={true}
                              defaultValue={extraction || {
                                title: "no",
                                value: 0,
                              }}
                              onChange={value => {
                                // // console.log('extraction',value)
                                setExtraction(value)
                              }}
                              data={{
                                title: "Estrazione",
                                items: [
                                  {
                                    title: "si",
                                    value: 1,
                                  },
                                  {
                                    title: "no",
                                    value: 0,
                                  },
                                ],
                              }}
                            ></MiCardRadioButtons>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CarouselItem>
                  {immission?.value === 1 && (
                  <CarouselItem
                    key={1}
                  >
                    <Row>
                      <Col lg={6}>
                        <Card
                          style={
                            {
                              // width: '18rem'
                            }
                          }
                          className="no-shadow rounded"
                        >
                          <img
                            alt="Sample"
                            className="rounded mx-3"
                            src="/imgs/Step-7-v.gif"
                          />
                          <CardBody>
                            <CardTitle tag="h5">SCHEDA DEFINIZIONE LOCALE</CardTitle>
                            
                            <CardText>
                              Inserisci la lunghezza e il numero di curve della tubazione corrugata di distribuzione che collega il BOX di distribuzione con il terminale in ambiente. 

                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6}>
                          <>
                            <div className="mb-2">
                              <h4>Mandata</h4>
                              <FormGroup>
                                <Label for="immissionTubeLength"  className="mb-1">
                                  Lunghezza tubo (immissione)
                                </Label>
                                <Input
                                  className="mb-3"
                                  id="immissionTubeLength"
                                  name="immissionTubeLength"
                                  type="number"
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                  value={immissionTubeLength}
                                  onChange={value =>
                                    setImmissionTubeLength(value.target.value)
                                  }
                                  step={0.01}
                                  min={0.1}
                                  max={100.0}
                                />
                                { immissionTubeLength && (immissionTubeLength < 0.1 || immissionTubeLength > 100.0) &&
                                  <Alert 
                                  color="warning" 
                                  className='mt-3'
                                  >
                                    Inserisci un valore compreso tra 0.1 e 100.0
                                  </Alert>
                                }
                              </FormGroup>
                              <FormGroup  >
                                <Label for="immissionNumTube"  className="mb-1">
                                  Numero curve (immissione)
                                </Label>
                                <Input
                                  className="mb-3"
                                  id="immissionNumTube"
                                  name="immissionNumTube"
                                  type="number"
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                  value={immissionNumTubes}
                                  onChange={value =>
                                    setImmissionNumTubes(value.target.value)
                                  }
                                  min={0}
                                  max={50}
                                />
                                { immissionNumTubes && (immissionNumTubes < 0 || immissionNumTubes > 50) &&
                                  <Alert 
                                  color="warning" 
                                  className='mt-3'
                                  >
                                    Inserisci un valore compreso tra 0 e 50
                                  </Alert>
                                }
                              </FormGroup>
                            </div>
                          </>
                      </Col>
                    </Row>
                  </CarouselItem>
                )}
                {extraction?.value === 1 && (
                  <CarouselItem
                    key={2}
                  >
                    <Row>
                      <Col lg={6}>
                        <Card
                          style={
                            {
                              // width: '18rem'
                            }
                          }
                          className="no-shadow rounded"
                        >
                          <img
                            alt="Sample"
                            className="rounded mx-3"
                            src="/imgs/Step-7-v.gif"
                          />
                          <CardBody>
                            <CardTitle tag="h5">SCHEDA DEFINIZIONE LOCALE</CardTitle>
                            <CardText>
                              Inserisci la lunghezza e il numero di curve della tubazione corrugata di distribuzione che collega il BOX di distribuzione con il terminale in ambiente. 
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6}>
                          <>
                            <div className="mb-2">
                              <h4>Estrazione</h4>
                              <FormGroup >
                                <Label for="example2"  className="mb-1">
                                  Lunghezza tubo (estrazione)
                                </Label>
                                <Input
                                  className="mb-3"
                                  id="extraction"
                                  name="extraction"
                                  type="number"
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                  value={extractionTubeLength}
                                  onChange={value =>
                                    setExtractionTubeLength(value.target.value)
                                  }
                                  step={0.01}
                                  min={0.1}
                                  max={100.0}
                                />
                                { extractionTubeLength && (extractionTubeLength < 0.1 || extractionTubeLength > 100.0) &&
                                  <Alert 
                                  color="warning" 
                                  className='mt-3'
                                  >
                                    Inserisci un valore compreso tra 0.1 e 100.0
                                  </Alert>
                                }
                              </FormGroup>
                              <FormGroup >
                                <Label for="example2"  className="mb-1">
                                  Numero curve (estrazione)
                                </Label>
                                <Input
                                  className="mb-3"
                                  id="extraction"
                                  name="extraction"
                                  type="number"
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                  value={extractionNumTubes}
                                  onChange={value =>
                                    setExtractionNumTubes(value.target.value)
                                  }
                                  min={0}
                                  max={50}
                                />
                                { 
                                  extractionNumTubes && (extractionNumTubes < 0 || extractionNumTubes > 50) &&
                                    <Alert 
                                    color="warning" 
                                    className='mt-3'
                                    >
                                      Inserisci un valore compreso tra 0 e 50
                                    </Alert>
                                }
                              </FormGroup>
                            </div>
                          </>
                      </Col>
                    </Row>
                  </CarouselItem>
                        )}
                </Carousel>
              ) : (
                <Row>
                  <Col>
                    <p style={{fontSize:16}}>
                      Sei sicuro di voler eliminare la stanza?
                    </p>
                  </Col>
                </Row>
              )}
            </MiModal>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ConfiguratorStep4
