import React, {useEffect, useState} from "react"
import { Card, CardBody, CardImg,Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MiTooltip } from "./MiTooltip"

export const MiCardRadioButtons = ({ 
  tooltip, 
  data, 
  onChange, 
  inline, 
  defaultValue, 
  optionStyle = {}, 
  multiple = false, 
  disabled = false, 
  title = '', 
  hideChoiceWhenDisabled = false, 
  alternativeSelectedStyle = false 
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(multiple ? [] : null)
  
  const handleClick = (i) => {
    if (disabled) return;
    let newSelected;

    if (multiple) {
      // Se multiple è abilitato, gestisci la selezione multipla
      if (selected?.includes(i)) {
        // Deseleziona l'opzione se è già selezionata
        newSelected = selected.filter((item) => item !== i);
      } else {
        // Seleziona l'opzione se non è già selezionata
        newSelected = [...selected, i];
      }
    } else {
      // Altrimenti, gestisci la selezione singola
      newSelected = i;
    }

    setSelected(newSelected);
    if (onChange) {
      // Passa l'array delle opzioni selezionate a onChange
      onChange(multiple ? newSelected.map((index) => data.items[index]) : data.items[i]);
    }
    
  }

  const isSelected = (i) => {
    if (multiple) {
      return selected?.includes(i);
    }
    return selected === i;
  }

  useEffect(() => {
    if (defaultValue && data?.items) {
      // // console.log('defaultValue', defaultValue, data?.items)
      if(multiple) {
        const multipleSelection = defaultValue?.map(selectedItem => {
          const foundItem = data?.items?.findIndex(item => 
            JSON.stringify(item) === JSON.stringify(selectedItem)
          );
          return foundItem;
        }).filter(item => item !== -1)
        // // console.log('multipleSelection', multipleSelection)
        
          setSelected(multipleSelection);
        
      }
      else {
        const i = data.items.findIndex(item => {
          if(item.hasOwnProperty('value') && defaultValue.hasOwnProperty('value')) {
            return item.value ? (item.value === defaultValue.value) : JSON.stringify(item) === JSON.stringify(defaultValue)
          } else  if (item.hasOwnProperty('title') && defaultValue.hasOwnProperty('title')) {
            return item.title ? (item.title === defaultValue.title) : JSON.stringify(item) === JSON.stringify(defaultValue)
          }
        })
        // console.log('gridoptions i', i)
        if (i !== -1) setSelected(i)
      }
    }
  }, [defaultValue, data])

  

  return (
    <div className="mi-card-radio-buttons-group" title={title}>
        {data?.title && 
          <Label className="title ms-2 mt-2 d-block">
            {t(data?.title)}
            {tooltip && <MiTooltip message={tooltip} />}
          </Label>
        }
      <div 
        className="mi-wrapper-radio-buttons"
        style={{
          whiteSpace: inline ? "nowrap" : 'normal',
          overflowX: inline ? "scroll" : 'visible',
          display: 'flex',
          flexFlow: 'row wrap'
        }}
      >
        {data.items && data.items.length > 0 ? (
          data.items.map((item, i) => 
          <Card
            key={i}
            className={`m-2 mt-0 mb-3no-shadow card-radio-button ${isSelected(i) || (disabled && isSelected(i)) ? (alternativeSelectedStyle ? 'alternative-active': 'active') : (hideChoiceWhenDisabled ? 'd-none':'')} ${item.img ? '' : 'no-image'}`}
            onClick={() => handleClick(i)}
            color="secondary"
            outline
            style={{
              opacity: disabled ? 0.5 : 1,
              display: "inline-block",
              position: "relative",
              maxWidth: item.img ? "250px": "auto",
              whiteSpace: "normal",
              ...optionStyle
            }}
          >
            <CardBody 
              className={`${(item.img || item.description) ? 'py-2' : 'py-0'}`}
            >
              {item.title && <div className="title">{t(item.title)}</div>}
              {item.description && <div className="description">{t(item.description)}</div>}
              {item.img && 
                <CardImg
                  className="mt-2 mb-2"
                  style={{
                    height: 70,
                    width: 'auto',
                    maxWidth: '100%',
                  }}
                  src={item.img}
                />
              }
              {
                item.text && <p style={{
                  lineHeight:'12px',
                  marginBottom: '5px',
                  lineHeight: '14px',
                }}>
                  <small dangerouslySetInnerHTML={{__html: item.text}}>
                  </small>
                </p>
              }
            </CardBody>
          </Card>
        )) : (
          <div className="text">no options</div>
        )}
      </div>
    </div>
  )
}
