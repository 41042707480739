import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import { getToken } from "helpers/api_helper"
import { useHistory, useLocation, useParams } from "react-router-dom"

import { post, put } from "helpers/api_helper"


// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import {
  Toast,
  ToastBody,
  Collapse,
  Container,
  Row,
  Col,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Button,
  Nav,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { MiDropodown } from "components/Common/mi/MiDropodown"
import { MiModal } from "components/Common/mi/MiModal"
import { ConfiguratorContext } from "context/configuratorContext"


const Header = props => {
  const { configuratorData, setConfiguratorData, user, isKit, setIsKit, setValidations, validations, shouldCalculate, allFormulas } = useContext(ConfiguratorContext)
  
  const [isOpen, setIsOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showToast, setShowToast] = useState(null)

  
  const [showCheckModal, setShowCheckModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showModalShare, setShowModalShare] = useState(false)
  const [showCheckModalDashboard, setShowCheckModalDashboard] = useState(false)
  const [hash, setHash] = useState(null)
  
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { configurationId, activeStep } = useParams()

  const toggle = () => setIsOpen(!isOpen)
  const toggleModal = () => setShowModal(!showModal)
  const toggleModalShare = () => setShowModalShare(!showModalShare)
  const toggleModalCheck = () => setShowCheckModal(!showCheckModal)
  const toggleModalCheckDashboard = () => setShowCheckModalDashboard(!showCheckModalDashboard)
  const toggleModalLogout = () => setShowLogoutModal(!showLogoutModal)
  

  const [canGenerate, setCanGenerate] = useState(false)

  const handleFinalOffer = () => {
    
    if (!configurationId){
      setShowCheckModal(true)
    } else {
      navigate.push('/offer/final/'+configurationId)
    }
  }
  

  const handleClick = (e) => {
    const id  = e.target.dataset.item;
    switch(id){
      case 'save':
        handleSave()
        break
      case 'kit-offer':
        localStorage.setItem("configuratorData", '')
        setConfiguratorData({isKit: true})
        setValidations({})
        setIsKit(true)
        setShowModal(true)
        break
      case 'free-offer':
        localStorage.setItem("configuratorData", '')
        setConfiguratorData({isKit: false})
        setValidations({})
        setIsKit(false)
        setShowModal(true)
        break
      case 'share':
        handleShare()
        break
      case 'professional-area':
        window.location.href = 'https://doc.mitsubishielectric.it/'
        break
      case 'logout':
        window.location.href = '/logout.php'
        break

    }
  };

  const handleShare = async () => {
    const res = await post('api/configuration/' + configurationId + '/share', {})
    if(!res) return
    setHash(res.split('/').pop())
    setShowModalShare(true)
    
  }

  const isValid = (data) => {
    let steps = 0
    const filter = Object.values(data)?.filter(v => {
      if(v?.hasOwnProperty('isValid')) {
        steps++
      }
      if(
        v?.hasOwnProperty('isValid') && 
        !v.isValid && 
        typeof v === 'object') return true
      return false
    } )
    console.log('isValid', filter.length, steps)
    return (filter.length === 0 && steps >= 7)

  }

  const handleSave = async (url = '') => {
    
    const body = {
      data: configuratorData,
      name: configuratorData?.stepData1?.nameOffer,
      published_at: configuratorData?.stepData1?.dateOffer,
      note: configuratorData?.stepData1?.noteOffer,
      is_complete: isValid(configuratorData) ? 1 : 0,
      user_name: configuratorData?.stepData1?.technicianOffer,
      is_kit: isKit ? 1 : 0
    }

    let saveOffer = null

    if(configurationId) {
      delete body.is_kit
      put('api/configuration/' + configurationId, body).then(res =>{
        setShowToast({
          show: true,
          type: 'success',
          message: 'Configurazione modificata con successo'
        })
      }).catch(e => {
        console.log('err', e.message)
        setShowToast({
        show: true,
        type: 'error',
        message: 'Impossibile salvare la configurazione. Riprova più tardi.'
      })})
      const to = setTimeout(()=> {
        setShowToast({show: false})
        clearTimeout(to)
      }, 5000)
    } else {
      post('api/configuration', body).then(res =>{
        if(res) {
          sessionStorage.setItem('toastMessage',JSON.stringify({message: 'Configurazione salvata con successo', type: 'success'}))
          if (url && url.length > 0) navigate.push(url + res)
          else navigate.push('/configurator/' + activeStep + '/' + res )
        }
        setShowToast({
          show: true,
          type: 'success',
          message: 'Configurazione salvata con successo'
        })
      }).catch(e => {
        console.log('err', e.response.data.message)
        setShowToast({
          show: true,
          type: 'error',
          message: 'Impossibile salvare la configurazione. ' + e.response.data.message + ' Riprova più tardi.'
        })

      })
      const to = setTimeout(()=> {
        setShowToast({show: false})
        clearTimeout(to)
      }, 5000)
      
    }
  }



  useEffect(() => {
    if(validations) {
      const formulas = JSON.parse(JSON.stringify(allFormulas))
      // if (shouldCalculate()) delete formulas?.machineCode
      const lastCheckedConfiguration = localStorage.getItem("lastCheckedConfiguration") ? JSON.parse(localStorage.getItem("lastCheckedConfiguration")) : ''
      const activeConfiguration = JSON.parse(JSON.stringify(configuratorData))
      delete activeConfiguration.stepData8
      delete lastCheckedConfiguration.stepData8
      let canGenerate = false
       if (JSON.stringify(lastCheckedConfiguration) !== JSON.stringify(activeConfiguration)){
        canGenerate = false
       } else {
        if (formulas?.machineCode && isValid(configuratorData)) {
          canGenerate = true
        }
       }
      setCanGenerate(canGenerate)
    }
  }, [configuratorData, activeStep])

  useEffect(() => {
    const toastMessage = sessionStorage.getItem('toastMessage')
    if(toastMessage && toastMessage.length > 0) {
      const message = JSON.parse(toastMessage)
      setShowToast({
        show: true,
        type: message.type,
        message: message.message
      })
      const to = setTimeout(()=> {
        setShowToast({show: false})
        clearTimeout(to)
        sessionStorage.removeItem('toastMessage')
      }, 5000)
    }
  }, [])

  const checkPath = (path) => {
    return pathname.includes(path)
  }



  return (
    <React.Fragment>
      <Container>
        <Navbar expand={"md"} className="px-2 justify-content-end">
            <Nav 
              className="navbar-brand abs" 
              navbar
              style={window.innerWidth <= 1024 ? {
                left: 0,
                transform: 'translate(0, 0px)'
              }: {}}  
            >
              {checkPath('configurator') &&  <MiDropodown
                maxWidth="150px"
                label={configuratorData?.stepData1?.nameOffer || 'untitled'}
                transparent={true}
                items={[
                  {
                    label: "Salva",
                    id: "save",
                  },
                  {
                    label: "Condividi",
                    id: "share",
                    disabled: configurationId > 0 ? false : true
                  },
                ]}
                onChange={handleClick}
              />}
            </Nav>
            <Nav
            >
              {checkPath('configurator') && 
              <Button
                disabled={!canGenerate}
                color="primary"
                onClick={()=> {
                  sessionStorage.setItem('lastFormulas', JSON.stringify(allFormulas))
                  handleFinalOffer()
                }}
              >Lista materiali</Button>}
              {checkPath('dashboard') && !user?.is_free_configuration_available && <Button
                color="primary"
                onClick={()=> {
                  setIsKit(user?.is_free_configuration_available ? false : true)
                  setConfiguratorData({isKit: user?.is_free_configuration_available ? false : true})
                  setShowModal(true)
                }}
              >Nuovo Progetto</Button>}

              {(checkPath('dashboard') && user?.is_free_configuration_available) ?
                <MiDropodown
                  label="Nuovo Progetto"
                  items={[
                    {
                      label: "Kit",
                      id: "kit-offer",
                    },
                    {
                      label: "Free",
                      id: "free-offer",
                    },
                  ]}
                  onChange={handleClick}
                /> : ''
              }
            </Nav>

          <NavbarBrand className="ms-4" onClick={() => {
            if(!configurationId && checkPath('configurator')) setShowCheckModalDashboard(true)
            else navigate.push('/dashboard')

          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M20.1923 24H4.80769C2.15385 24 0 21.8325 0 19.1618V11.2948C0 9.85304 0.634616 8.49834 1.73077 7.57907L9.42308 1.12489C11.2019 -0.374962 13.7981 -0.374962 15.5769 1.12489L17.2981 2.56668V1.7345C17.2981 1.2023 17.7308 0.766858 18.2596 0.766858C18.7885 0.766858 19.2212 1.2023 19.2212 1.7345V4.62776C19.2212 5.00514 19.0096 5.34382 18.6635 5.50832C18.3269 5.66314 17.9231 5.61476 17.6442 5.37285L14.3462 2.60538C13.2788 1.70547 11.7212 1.70547 10.6538 2.60538L2.96154 9.05957C2.29808 9.61113 1.92308 10.4239 1.92308 11.2852V19.1521C1.92308 20.7487 3.22115 22.055 4.80769 22.055H20.1923C21.7788 22.055 23.0769 20.7487 23.0769 19.1521V11.2852C23.0769 10.4239 22.7019 9.61113 22.0385 9.05957L20.7692 7.99516C20.3654 7.65649 20.3077 7.04687 20.6442 6.63078C20.9808 6.22437 21.5865 6.16631 22 6.50499L23.2692 7.5694C24.3654 8.48866 25 9.84336 25 11.2852V19.1521C25 21.8228 22.8462 23.9903 20.1923 23.9903V24Z"
                fill="#2D2D2D"
              />
            </svg>
          </NavbarBrand>
            <svg
              viewBox="0 0 24 24"
              fill="black"
              height="30"
              width="30"
              strokeWidth={1.6}
              style={{
                marginRight: -35
              }}

            >
              <path d="M12 4a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0 014-4m0 2a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2m0 7c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1z" />
            </svg>
          <MiDropodown
                // customClasses="mdi-account-outline"
                label={''}
                transparent={true}
                items={[
                  {
                    label: "Area professionisti",
                    id: "professional-area",
                  },
                  {
                    label: "Logout",
                    id: "logout"
                  },
                ]}
                onChange={handleClick}
              />
        </Navbar>
      </Container>
      {
        showToast?.show && 
        <Toast
          className={`toast-${showToast?.type} position-fixed top-1 end-0 p-3 me-3`}
          isOpen={showToast?.show}
          fade={true}
          onClose={() => setShowToast({show: false})}
        >
          <ToastBody>
            {showToast?.message}
          </ToastBody>
        </Toast>
      }
      <MiModal
        toggle={toggleModal}
        title={"Attenzione"}
        show={showModal}
        handleBack={toggleModal}
        handleSave={() => {
          setValidations({})
          toggleModal()
          navigate.push('/configurator/1')
        }}
      >
        <p>
        Questo tool permette di esportare una lista di unità, componenti e accessori necessari alla realizzazione di un sistema di ventilazione meccanica controllata per applicazione residenziale sulla base di dati geometrici dell’edificio e di preferenze installative e di carattere progettuale.
        </p>

        <p>Il calcolo della portata di aria necessaria da introdurre nei locali è basato su un «coefficiente di ricambio aria» che è a discrezione dell’utente. 
        Mitsubishi Electric declina ogni responsabilità legata ad una erronea progettazione dell’impianto. </p>

        <p>
        Verificare le condizioni di impiego del recuperatore di calore riportati sul manuale tecnico.	
        </p>

        <p>
        La lista componenti fornita dal tool è realizzata basandosi sui dati di input forniti.
        </p>

        <p>
        Al momento dell'accettazione verrà eseguito il dimensionamento definitivo; le quantità indicate potranno 	
        quindi subire variazioni dipendenti da dati di input più dettagliati che potranno essere forniti.
        </p>
      </MiModal>
      <MiModal
        isOpen={showModalShare}
        toggle={toggleModalShare}
        title={"Condividi configurazione"}
        show={showModalShare}
        handleBack={toggleModalShare}
      >
        Con questo link potrai condividere la configurazione: <br/>
          <code className="p-2 py-3 d-block border border-primary position-relative cursor-pointer" 
            onClick={() => {
              navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + '/share/' + hash)
              setShowToast({
                show: true,
                type: 'success',
                message: 'Link copiato negli appunti'
              })
              toggleModalShare()
              const to = setTimeout(()=> {
                setShowToast({show: false})
                clearTimeout(to)
              }, 5000)
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}  stroke="currentColor" style={{
              width: 20,
              height: 20,
              position: 'absolute',
              top: 10,
              right: 10
            
            }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
            </svg>

            {window.location.protocol + '//' + window.location.host + '/share/' + hash}
          </code>
      </MiModal>
      <MiModal
        isOpen={showLogoutModal}
        toggle={toggleModalLogout}
        title={"Attenzione!"}
        show={showLogoutModal}
        handleBack={toggleModalLogout}
        handleSave={()=> window.location.href = '/logout.php' }
        labelBtnSave="Esci"
        labelBtnBack="Indietro"

      >
        <Container>
          <Row>
            <Col>
              <p>
                Abbandonando l'applicazione i lavori non salvati saranno persi. Confermi di voler proseguire?
              </p>
            </Col>
          </Row>
        </Container>
      </MiModal>
      <MiModal
        isOpen={showCheckModal}
        toggle={toggleModalCheck}
        title={"Attenzione!"}
        show={showCheckModal}
        handleBack={toggleModalCheck}
        handleSave={()=> handleSave('/offer/final/')}
        labelBtnSave="Salva"

      >
        <Container>
          <Row>
            <Col>
              <p>
                Attenzione! Prima di procedere con la generazione del report, è necessario salvare la configurazione.
              </p>
            </Col>
          </Row>
        </Container>
      </MiModal>
      <MiModal
        isOpen={showCheckModalDashboard}
        toggle={toggleModalCheckDashboard}
        title={"Attenzione!"}
        show={showCheckModalDashboard}
        handleBack={toggleModalCheckDashboard}
        handleNext={()=>{
            navigate.push('/dashboard')
        }}
        handleSave={handleSave}
        labelBtnSave="Salva"
      >
        <Container>
          <Row>
            <Col>
              <p>
              Attenzione! Abbandonando questa pagina le modifiche non salvate andranno perse. Salva la configurazione prima di procedere.
              </p>
            </Col>
          </Row>
        </Container>
      </MiModal>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
