import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
// const API_URL = "https://toolvmc.mitsubishielectric.it/"
const API_URL = "http://vmc.betatest.mitsubishielectric.it/"

const axiosApi = axios.create({
  baseURL: API_URL,
})

const tokenApi = axios.create({
  // baseURL: 'https://doc.mitsubishielectric.it/'
  baseURL: 'http://doc.betatest.mitsubishielectric.it/'
})

axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.common["Content-Type"] = 'application/json'
// axiosApi.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function getUser(url = 'api/vmc/auth', config = {}) {
  return await tokenApi.get(url, { 
    headers: {
      'Authorization': token
    },  
    ...config 
  }).then(response => response.data)
}
 
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}





